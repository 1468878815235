import React from "react";
import Ahmed from "../../img/board2024/Ahmed Khaled.jpeg";
import Rawan from "../../img/board2024/Rawan Tarek.jpg"

export default function Graphic() {
  return (
    <section className="section committee">
      <div className="container">
        <h2>Graphic Design Committee</h2>
        <div className="supervisors">
          <div>
            <img src={Rawan} alt="" />
            <h3>Rawan Tarek</h3>
            <p>committee head</p>
          </div>
          <div>
            <img src={Ahmed} alt="" />
            <h3>Ahmed Khaled</h3>
            <p>vice committee head</p>
          </div>
        </div>
      </div>
    </section>
  );
}
