import React from "react";
import Fatma from "../../img/board2024/Fatma Elzahraa Ashraf.jpeg"
import Kareem from "../../img/board2024/Kareem Tamer.jpeg"

export default function Technical() {
  return (
    <section className="section committee">
      <div className="container">
        <h2>Technical Committee</h2>
        <div className="supervisors">
          <div>
            <img src={Kareem} alt="" />
            <h3>Kareem Tamer</h3>
            <p>committee head</p>
          </div>
          <div>
            <img src={Fatma} alt="" />
            <h3>Fatma Elzahraa Ashraf</h3>
            <p>Vice committee head</p>
          </div>
        </div>
      </div>
    </section>
  );
}
