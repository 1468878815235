import React from "react";
import Omar from "../../img/board2024/Omar Mahmoud.jpg"

export default function Video() {
  return (
    <section className="section committee">
      <div className="container">
        <h2>Video Production Committee</h2>
        <div className="supervisors">
          <div>
            <img src={Omar} alt="" />
            <h3>Omar Mahmoud</h3>
            <p>committee head</p>
          </div>
          {/* <div>
            <img src={Alaa} alt="" />
            <h3>Alaa Usama</h3>
            <p>vice committee head</p>
          </div> */}
        </div>
      </div>
    </section>
  );
}
