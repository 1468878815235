import React from "react";
import Nouran from "../../img/board2024/Nouran Mousa.jpg"
import Mariam from "../../img/board2024/Mariam Mahmoud.jpg"

export default function SMM() {
  return (
    <section className="section committee">
      <div className="container">
        <h2>SMM Committee</h2>
        <div className="supervisors">
          <div>
            <img src={Mariam} alt="" />
            <h3>Mariam Mahmoud</h3>
            <p>committee head</p>
          </div>
          <div>
            <img src={Nouran} alt="" />
            <h3>Nouran Mousa</h3>
            <p>Vice committee head</p>
          </div>
        </div>
      </div>
    </section>
  );
}
