import React from "react";
import Mazen from "../../img/board2024/Mazen Mahmoud.jpg"
import Zaynab from "../../img/board2024/Zaynab Mostafa .jpg"

export default function PR() {
  return (
    <section className="section committee">
      <div className="container">
        <h2>PR Committee</h2>
        <div className="supervisors">
          <div>
            <img src={Zaynab} alt="" />
            <h3>Zaynab Mostafa</h3>
            <p>committee head</p>
          </div>
          <div>
            <img src={Mazen} alt="" />
            <h3>Mazen Mahmoud</h3>
            <p>Vice committee head</p>
          </div>
        </div>
      </div>
    </section>
  );
}
